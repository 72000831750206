<template>
  <v-dialog v-model="showLoading" persistent width="300">
    <v-card class="pt-4">
      <v-card-text>
        処理中です...
        <v-progress-linear indeterminate class="mb-0" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardCoreLoadings',
  computed: mapGetters(['showLoading']),
}
</script>
